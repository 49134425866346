.th-padding {
    padding: 0.75rem 0.75rem !important;
}
.custom-dropdown-menu {
    max-height: none; /* Rimuovi l'altezza massima */
    position: absolute;
    bottom: 100%; /* Allinea il menu verso l'alto */
}
.select-z-index {
    z-index: 5; /* Assicurati che questo sia sufficientemente alto */
    position: relative; /* Necessario per rendere efficace lo z-index */
}
.pagination-z-index {
    z-index: 1; /* Più basso del menu a tendina */
    position: relative; /* Potrebbe non essere necessario, a meno che non si stia modificando lo z-index */
}
.no-margin {
    margin: 0px !important;
}
.text-head {
    text-transform: uppercase!important;
}
.text-body {
    color: #78829D!important;
    font-size: 1.15rem!important;
}
  