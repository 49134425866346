//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

// Theme colors
// Primary
$primary:       									#009ef7;
$primary-active:    								#0095e8;
$primary-light:    								    #f1faff;
$primary-light-dark:    							#212e48;
$primary-inverse:  									$white;

// Success
$success:       									#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #7239ea;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    #f1416c;
$danger-active:    									#d9214e;
$danger-light:    									#fff5f8;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;

/* Personalizzati stati ordini */

// Ricevuto
$ricevuto:       									#f1416c;
$ricevuto-active:    								#d9214e;
$ricevuto-light:    								#fff5f8;
$ricevuto-light-dark:    							#3a2434;
$ricevuto-inverse:  								$white;

// In lavorazione
$inlavorazione:       								#db741a;
$inlavorazione-active:    							#ff7514;
$inlavorazione-light:    							#ffecda;
$inlavorazione-light-dark:    						#1c3238;
$inlavorazione-inverse:  							$white;

// Pronto
$pronto:       									    #7239ea;
$pronto-active:    									#5014d0;
$pronto-light:    									#f8f5ff;
$pronto-light-dark:    								#2f264f;
$pronto-inverse:  									$white;

// Spedito
$spedito:       									#0c4ea5;
$spedito-active:    							    #144d8a;
$spedito-light:    									#f1faff;
$spedito-light-dark:    							#1c3238;
$spedito-inverse:  									$white;

// Attivo
$attivo:       									    #50cd89;
$attivo-active:    								    #47be7d;
$attivo-light:    								    #e8fff3;
$attivo-light-dark:    							    #1c3238;
$attivo-inverse:  								    $white;

// In scadenza
$inscadenza:       									#FFC51A;
$inscadenza-active:    								#FFC000;
$inscadenza-light:    								#FFFAEC;
$inscadenza-light-dark:    							#392f28;
$inscadenza-inverse:  								$white;

// Scaduto
$scaduto:       									#872D19;
$scaduto-active:    								#7b1b02;
$scaduto-light:    								    #E6DCDF;
$scaduto-light-dark:    							#392f28;
$scaduto-inverse:  								    $white;

// Inattivo
$inattivo:       									#C2C2C2;
$inattivo-active:    								#979797;
$inattivo-light:    								#fff5f8;
$inattivo-light-dark:    							#392f28;
$inattivo-inverse:  								$white;


// Nero
$nero:       									#0a0706;
$nero-active:    								#070404;
$nero-light:    								#E6DCDF;
$nero-light-dark:    								#392f28;
$nero-inverse:  								$white;